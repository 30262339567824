/* website: 3264-eliteford
 * created at 2021-02-15 17:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/ford.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.hide-credit-fees{
    .tile-payment-detail{
      &.small{
        display: none;
      }
    }
  }
}